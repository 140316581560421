import Model from '../Model';

class User extends Model{

	getUser(uid, callback){
		this.api.firestore.collection("users").doc(uid).get().then(callback);
	}


	getUsers(callback){
		this.api.firestore.collection("users").get().then(function(els){
			let usersList = {};
			els.forEach((el) => { usersList[el.id] = el.data(); });
			callback(usersList);
		});
	}

	setUser(uid,user,callback){
		this.api.firestore.collection("users").doc(uid).set(user, {merge: true}).then(callback);
	}

	getChild(uid, related, callback){
		this.api.firestore.collection(related).where("userUid", "==", uid).get().then(function(els){
			let userChildren = {};
			els.forEach((el) => { userChildren[el.id] = el.data(); });
			callback(userChildren);
		}).catch(function(error) {
			console.log("Error getting user Links: ", error);
		});
	}


}

export default User;