// Librerías
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { FirebaseContext } from '../firebase';

// Herramientas
import AddToHomescreen from '../tools/AddToHomescreen';

// Componentes
import Loading from './Common/Loading';
import TopMenu from './Common/TopMenu';
import BottomMenu from './Common/BottomMenu';
import Login from './Login/Index';
import Home from './Home/Index';
import Users from './Users/Index';

// Componente App
class App extends React.Component {
    
    // Cogemos referencia a Firebase del Context
    static contextType = FirebaseContext;

    // Inicializamos el estado
    constructor(props) { 
        super(props);
        //this.state = { aUserList: [] };

        // Inicializamos el estado
        this.state = {
            currUser: { connecting: true, connected: false, uid: ""}
        };
    }

    // Cargamos el componente
    componentDidMount(){

        // Referencia a Firebase
        let api = this.context;

        // Comprobamos si hay un usuario logueado y si es así, lo metemos en el State
        api.Models.Auth.onChangeUser(function(fbUser){
            // Si hay Usuario
            if(fbUser){
                // Indicamos que el estado es conectado
                this.setState({currUser:{ connected: true }});
                // Obtenemos los datos del usuario conectado
                api.Models.User.getUser(fbUser.uid, function(user){
                    this.setState({currUser: {...user.data(), ...{connecting: false, connected: true}}});
                }.bind(this));
            // Si NO hay un usuario logueado, borramos los posibles datos que pudiera haber de una sesión anterior
            } else {
                this.setState({currUser: { connecting: false, connected: false, uid: "" }});
            }
        }.bind(this));
    }

    // Renderizamos la lista de tareas
    render(){

        if(this.state.currUser.connecting){
            return <Loading />
        }else if(this.state.currUser.connected){
            return (
                <Router>
                    <AddToHomescreen />
                    <div className="App">
                        <TopMenu />
                        <Switch>
                            <Route exact path="/"><Home currUser={this.state.currUser} /></Route>
                            <Route exact path="/usuarios"><Users /></Route>
                        </Switch>
                        <BottomMenu />
                    </div>
                </Router>
            );
        }else{
            return (
                <div id="login-container">
                    <AddToHomescreen />
                    <Login />
                </div>
            );
        }
    }
}

export default App;