// Librerías
import React from 'react';
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

// Modelos
import Auth from './models/Auth/Index';
import User from './models/Auth/User';
import Link from './models/Links/Index';

// Constantes
import GLOBALS from './defines';
//const GLOBAL = require('./defines');
const FirebaseContext = React.createContext(null);

class Firebase {
    constructor() {

        app.initializeApp(GLOBALS.CONFIG);

        // API de Firebase
        this.api = {
            auth: app.auth(),
            firestore: app.firestore(),
            storage: app.storage()
        };

        // Variables de entorno accesibles de la Aplicación
        this.App = GLOBALS.APP;

        // Rutas de la aplicación
        this.Paths = GLOBALS.PATHS

        // Modelos
        this.Models = {
            Auth: new Auth(this.api),
            User: new User(this.api),
            Link: new Link(this.api)
        };

    }


}

export default Firebase;
export { FirebaseContext };