// Librerías
import React from 'react';
import { FirebaseContext } from '../../firebase';

class UsersListTable extends React.Component {

	// Cogemos referencia a Firebase del Context
	static contextType = FirebaseContext;

	render(){
		
		const usersList = this.props.userslist;
		const paths = this.context.Paths;

		return(

			<div id="usersListTable" className="container">
				<div className="row">
					<div className="col-6 titulo">
						<h1 className="display-4">Usuarios &nbsp;</h1>
					</div>
					<div className="col-6 toolbar">
						<button id="openUse" type="button" className="btn btn-primary" uid="" onClick={this.props.edit}>Nuevo</button>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="table-responsive-md">
							<table className="table table-hover">
								<thead className="thead-dark">
									<tr>
										<th scope="col">Imagen</th>
										<th scope="col">Usuario</th>
										<th scope="col">Nombre</th>
										<th scope="col">Apellidos</th>
										<th scope="col">¿Activo?</th>
										<th scope="col">Opciones</th>
									</tr>
								</thead>
								<tbody>
									{Object.keys(usersList).map(function(uid){
										let userImage = usersList[uid].image || (paths.assets.images + "nouser.jpg");
										return (
										<tr key={uid} uid={uid} onClick={this.props.edit} style={{cursor: "pointer"}}>
											<th scope="row"><div className="user-image" style={{backgroundImage: "url("+userImage+")"}} alt={uid}></div></th>
											<td>{usersList[uid].username}</td>
											<td>{usersList[uid].firstname}</td>
											<td>{usersList[uid].surnames}</td>
											<td>{usersList[uid].activated?"Sí":"No"}</td>
											<td><button className="btn btn-info">Llamar</button></td>
										</tr>
										)
									}.bind(this))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default UsersListTable;