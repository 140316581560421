// Librerías
import React from 'react';
import { FirebaseContext } from '../../firebase';

// Helpers
import * as _ from 'underscore';

// Estilos
import '../../styles/parts/components/login.scss'

class Login extends React.Component {
	// Cogemos referencia a Firebase del Context
	static contextType = FirebaseContext;

	// Inicializamos el estado
  	constructor(props){
		super(props);
		this.state = { 
			status:"init", 
			username:"", 
			password:"", 
			message: "" 
		};
		this.onChangeLogin = this.onChangeLogin.bind(this);
		this.onSubmitLogin = this.onSubmitLogin.bind(this);		
	}
	
	onChangeLogin(e) {
  		if("txtUsername" === e.target.id) this.setState({username: e.target.value, message: ""});
  		if("txtPassword" === e.target.id) this.setState({password: e.target.value, message: ""});
	}	
	
	onSubmitLogin(e){
		e.preventDefault();
		
		// Referencia a Firebase
		let api = this.context;
		
		// Validamos que los datos estén bien
		if(_.isEmpty(this.state.username)) { this.setState({message:"Debe indicar el Usuario"}); return; }
		if(_.isEmpty(this.state.password)) { this.setState({message:"Debe indicar la contraseña"}); return; }
		
		// Si todo está ok, ponemos el estado "comprobando"
		this.setState({ status:"cargando" });
		
		// Preguntamos a la API si el login es correcto. 
		// Ejemplo: 
		//		el correo electrónico <this.state.username>
		//		la contraseña <this.state.password>
		//		la respuesta <response>
		api.Models.Auth.doLogin(this.state.username, this.state.password, function(response){
			// Si el login se hizo bien, devolverá un True, y si hubo error, devolverá un objeto Error
			if(_.isObject(response) && _.isError(response)){
				// Según el código de error pondremos el mensaje que corresponda
				switch(response.code){
					case "auth/user-not-found": this.setState({ status: "init", message: "El Usuario <" + this.state.username + "> no existe"}); break;
					case "auth/invalid-email": this.setState({ status: "init", message: "El Usuario <" + this.state.username + "> no existe"}); break;
					case "auth/wrong-password":this.setState({ status: "init", message: "La Contraseña indicada para el Usuario <" + this.state.username + "> no es válida"}); break;
					case "auth/too-many-requests":this.setState({ status: "init", message: "Demasiados intentos"}); break;
					default: this.setState({ status: "init", message: "Error: " + response.code});
				}
			}
			// NOTA: Si el login se hizo bien, no tenemos que controlar nada aquí. Desde App se detectará el usuario
			// logueado y se activarán todo lo necesario para permitir la entrada.
		}.bind(this));
		
	}
	
	render(){

		const paths = this.context.Paths

		return(
			<div className="login text-center">
				<form className="login-formulario" onSubmit={this.onSubmitLogin}>
					<img className="mb-4" src={paths.assets.images + "ch42-logo.png"} alt="ch42 channel shortner logo" />
					<h1 className="h3 mb-3 font-weight-normal">Login</h1>
					<label htmlFor="txtUsername" className="sr-only">Usuario</label>
					<input type="text" id="txtUsername" className="form-control" value={this.state.username} onChange={this.onChangeLogin} placeholder="Dirección de correo" />
					<label htmlFor="txtPassword" className="sr-only">Password</label>
					<input type="password" id="txtPassword" className="form-control" value={this.state.password} onChange={this.onChangeLogin} placeholder="Contraseña" />
					
					<button className="btn btn-lg btn-primary btn-block" type="submit" disabled={"cargando"===this.state.status}>
						{this.state.status==="cargando" && <span className="spinner-border spinner-border-sm" role="status" style={{width: "1.5rem", height: "1.5rem"}} aria-hidden="true"></span>}
						{this.state.status==="cargando"?" Comprobando...":"Aceptar"}
					</button>					
					
					{!_.isEmpty(this.state.message) && (<div><br /><div className="alert alert-danger" role="alert">{this.state.message}</div></div>)}
				</form>
			</div>
		);
		
	}
	
}
// required autofocus
export default Login;
