export default {
    APP: {
        name: process.env.REACT_APP_NAME,
        shortname: process.env.REACT_APP_SHORTNAME,
        description: process.env.REACT_APP_DESCRIPTION,
        url: process.env.REACT_APP_URL
    },

    CONFIG: {
        apiKey: process.env.REACT_APP_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_DATABASE_URL,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    },

    PATHS: {
        assets: {
            images: "/assets/images/",
            iconsapp: "/assets/icons/",
            media: ""
        }
    }
};