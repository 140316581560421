import Model from '../Model';

class Link extends Model{

	getLink(uid, callback){
		this.api.firestore.collection("links").doc(uid).get().then(callback);
	}


	getLinks(callback){
		this.api.firestore.collection("links").get().then(function(els){
			let linksList = {};
			els.forEach((el) => { linksList[el.id] = el.data(); });
			callback(linksList);
		});
	}

	setLink(uid, link, callback){
		this.api.firestore.collection("links").doc(uid).set(link, {merge: true}).then(callback);
	}

	getParent(uid, parent, callback){
		this.api.firestore.collection(parent).doc(uid).get().then(callback);
	}


}

export default Link;