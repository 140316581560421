// Librerías
import React from 'react';
import { FirebaseContext } from '../../firebase';

// Estilos
import '../../styles/parts/components/bottommenu.scss'

class BottomMenu extends React.Component {
	
	// Cogemos referencia a Firebase del Context
	static contextType = FirebaseContext;

	constructor(props){
		super(props);
		this.onClickPrueba = this.onClickPrueba.bind(this);
	}
	

	onClickPrueba(){
		//
	}
	
	render(){
		
		return(
			
			<footer className="footer"></footer>

		);
		
	}
	
}

export default BottomMenu;
