// Librerías
import React from 'react';
import { store } from 'react-notifications-component';

// Componentes
import HomeComponent from './Component';
import HomeGraphic from './Graphic';

// Estilos
import '../../styles/parts/components/home.scss'

class Home extends React.Component {
	
	componentDidMount(){
		store.addNotification({
			title: "Bienvenido!",
			message: "Desde aquí puede manejar el funcionamiento de la aplicación Acortador de URL",
			type: "success",
			insert: "top",
			container: "top-right",
			animationIn: ["animated", "fadeIn"],
			animationOut: ["animated", "fadeOut"],
			dismiss: { duration: 5000, onScreen: true}
		});
	}

	render(){

		return(
		
			<React.Fragment>
			
				<div className="jumbotron">
					<div className="container">
						<h1 className="display-3">Bootstrap Admin</h1>
						<p>Desde esta aplicación puede controlar todo lo que pasa en una aplicación hecha con Firebase.</p>
						<p>Usuario Logueado: <code>{this.props.currUser.firstname} {this.props.currUser.surnames}</code></p>
					</div>
				</div>
				
				<div className="container">
					<div className="row">
			
						<div className="col-md-4">
							<HomeComponent titulo="Uno">
								<p>Donec id elit non mi porta gravida at eget metus. Fusce dapibus, tellus ac cursus commodo.</p>
								<p><a className="btn btn-secondary" href="/" role="button">Ver más &raquo;</a></p>
							</HomeComponent>
						</div>
						
						<div className="col-md-4">
							<HomeComponent titulo="Dos">
								<p>Donec id elit non mi porta gravida at eget metus. Fusce dapibus, tellus ac cursus commodo.</p>
								<p><a className="btn btn-secondary" href="/" role="button">Ver más &raquo;</a></p>
							</HomeComponent>
						</div>
						
						<div className="col-md-4">
							<HomeGraphic titulo="Tres" />
						</div>
					
					</div>
				</div>
				
			</React.Fragment>
		
		);
		
	}
	
}

export default Home