// Librerías
import React from 'react';

// Helpers
import * as _ from 'underscore'

class UserLinksTable extends React.Component {

	// ================================================================================================================================================================================================
	//	Eventos
	// ================================================================================================================================================================================================

	// ================================================================================================================================================================================================
	//	onClickEdit As Void
	//		Evento click sobre el botón <Editar> del listado de la función <render>
	// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
	//	e As Event
	// ================================================================================================================================================================================================
	onClickEdit(e){
		e.preventDefault();
		
		// Cogemos el valor del atributo UID del elemento (este atributo podría estar vacío si se trata del botón "Nuevo")
		let uid = e.currentTarget.getAttribute("uid");
		
		// Si el UID no está vacío, cogemos referencia al usuario que corresponda del Array de usuarios de la tabla
		// Abrimos el formulario con ese usuario (si no hay usuario saldrá vacío para crear uno nuevo)
		if(!_.isEmpty(uid)) this.userFormOpen(this.props.userLinks[uid]);
	}

	// ================================================================================================================================================================================================
	//	onClickDelete As Void
	//		Evento click sobre el botón <Borrar> del listado de la función <render>
	// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
	//	e As Event
	// ================================================================================================================================================================================================
	onClickDelete(e){
		e.preventDefault();
		
		// Cogemos el valor del atributo UID del elemento (este atributo podría estar vacío si se trata del botón "Nuevo")
		let uid = e.currentTarget.getAttribute("uid");
		
		// Si el UID no está vacío, cogemos referencia al usuario que corresponda del Array de usuarios de la tabla
		// Abrimos el formulario con ese usuario (si no hay usuario saldrá vacío para crear uno nuevo)
		if(!_.isEmpty(uid)) this.userFormOpen(this.props.userLinks[uid]);
	}


	// Renderizado del componente, la salida HTML
	render(){

		// Donde almacenar el HTML del listado de los Enlaces de Usuario
		let htmlUserLinks;

		// Si el Usuario NO tiene ningún Enlace para mostrar
		if (!this.props.userLinks) {
			htmlUserLinks = <li className="col-12 userlink"><div className="alert alert-warning" role="alert">El Usuario no tiene ning&uacute;n enlace</div></li>
		}else{
			// Array de todas los UID de los enlaces del Usuario
			htmlUserLinks = Object.keys(this.props.userLinks || {}).map(function(uid,i){ return(
				<tr key={uid} uid={uid} className="userlink">
					<td><button className="btn btn-info btn-sm edit" uid={uid} onClick={this.onClickDelete.bind(this)}>Editar</button></td>
					<td><button className="btn btn-danger btn-sm delete" uid={uid} onClick={this.onClickEdit.bind(this)}>Borrar</button></td>
					<td>{this.props.userLinks[uid].name}</td>
					<td>{this.props.userLinks[uid].source}</td>
					<td>{this.props.userLinks[uid].target}</td>
					<td>{this.props.userLinks[uid].activated?"Sí":"No"}</td>
				</tr>
			)}.bind(this));
		}
		return (
			<div id="user-links-container" className="user-links-container">
				<div className="row">
					<div className="col-6 title"><h1 className="display-6">Enlaces &nbsp;</h1></div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="table-responsive-md">
							<table className="table table-hover">
								<thead className="thead-dark">
									<tr>
										<th scope="col" colSpan="2">Opciones</th>
										<th scope="col">Nombre</th>
										<th scope="col">URL Original</th>
										<th scope="col">URL corta</th>
										<th scope="col">¿Activo?</th>
									</tr>
								</thead>
								<tbody>{htmlUserLinks}</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default UserLinksTable;