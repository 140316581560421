// Librerías
import React from 'react';
import ReactDOM from 'react-dom';
import Firebase, { FirebaseContext } from './firebase';
import ReactNotification from 'react-notifications-component' 

// Componentes
import App from './components/App';
import * as serviceWorker from './serviceWorker';

// Estilos
import './styles/inc/notification/notification.scss';
import './styles/main.scss';

// Boostrap
import 'bootstrap/dist/css/bootstrap.min.css';
//import $ from 'jquery';
//import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';

// Arranque
ReactDOM.render(<FirebaseContext.Provider value={new Firebase()}><ReactNotification /><App /></FirebaseContext.Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
