import React from 'react';
//import '../../estilos/Componente.scss'

class HomeComponent extends React.Component {
	
	
	render(){
		
		return(
		
			<React.Fragment>
				<h2>{this.props.titulo}</h2>
				{this.props.children}
			</React.Fragment>
		
		);
		
	}
	
}

export default HomeComponent;