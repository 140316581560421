import Model from '../Model';

class Auth extends Model{

	// ================================================================================================================================================================================================
	//	doLogin
	//		Hace el login a la aplicación
	// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
	// 	username As String
	//		El ID de Usuario (o correo electrónico)
	//	password As String
	//		La contraeña
	//	callback As Function
	//		La función de retorno
	// ================================================================================================================================================================================================

	doLogin(username, password, callback){
		this.api.auth.signInWithEmailAndPassword(username, password).then(() => {
			callback(true);
		}).catch(error => {
			callback(error);
		});
	};

	// ================================================================================================================================================================================================
	//	doLogout
	//		Cierra la sesión del Usuario en la aplicación
	// ================================================================================================================================================================================================

	doLogout(){ this.api.auth.signOut(); }
	
	// ================================================================================================================================================================================================
	//	onChangeUser
	//		Cambia de Usuario
	// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
	//	callback As Function
	//		La función de retorno al hacer la autenticación del cambio de Usuario
	// ================================================================================================================================================================================================

	onChangeUser(callback){
		this.api.auth.onAuthStateChanged(callback);
	};
}

export default Auth;