/** =============================================================================================================================================================================================
 *	hasStorage
 * 		Comprueba si el Navegador soporta LocalStorage, devuelve TRUE, o no, devuelve FALSE
 * ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
 * 	@returns Boolean
 * ==============================================================================================================================================================================================*/
export const hasStorage = () => {
	return 'undefined' !== typeof(Storage) ? true : false;
};

/** =============================================================================================================================================================================================
 * 	slug 
 * 		Convierte un cadena String a formato Slug, es decir, sin caracteres especiales y sin espacios en blanco, sustituyendo éstos por guiones
 * ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
 * 	@param String title, el texto que se va a transformar
 * 	@param String separator, el separador o caracter sustituyente
 * ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
 * 	@returns String
 * ==============================================================================================================================================================================================*/
export const slug = (title, separator) => {
	if('undefined' === typeof title) return title;
	if('undefined' === typeof separator) separator = '-';

	return title.trim()
		.replace(/:/g, '')
		.replace(/flip/g, separator)
		.replace(/[^a-z0-9-]/gi, '-')
		.replace(/-+/g, '-')
		.replace(/^-|-$/g, '')
		.toLowerCase();
}

/** =============================================================================================================================================================================================
 *	hasEventListener
 * 		Comprueba que el objeto seleccionado mediante jQuery y el parametro (selector) tenga el evento asignado (evetName)
 * ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
 * @param jQuery $, jQuery
 * @param String selector, el selector por el que obtenemos el objeto del DOM
 * @param String eventName, el nombre del onctrolador del evento (event handler)
 * ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
 * @return Boolean
 * ==============================================================================================================================================================================================*/
export const hasEventListener = ($, selector, eventName) => {

        if(!$(selector).length || !eventName) return false;

        eventName = (eventName + '').split('.');
        var d = $._data ? $._data($(selector)[0], 'events') : $(selector).data('events'),
            a = [],
            e = eventName[0],
            n = eventName[1],
            x = 0,
            y, z;

        if(d && '' === e){
            for(y in d){
                for(z in d[y]){
                    if(d[y][z].namespace === n){
                        a[x] = y;
                        x ++;
                    }
                }
            }
            if(!a.length) return false;
            return a;
        }
        if(d && d[e]){
            if(n){
                for(y in d[e]) if(d[e][y].namespace === n) return true
                return false;
            }
            return true;
        }
        return false;

    }

/** =============================================================================================================================================================================================
 *	checkPlatform
 * 		Devuelve un objeto con diferentes propiedades listadas a continuación para saber sobre qué plataforma está corriedno la aplicación
 * ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
 * 	@returns Object{
 * 		isIosDevice (Boolean)
 *  	isSamsung (Boolean)
 *  	isEdge (Boolean)
 *  	isFireFox (Boolean)
 *  	isOpera (Boolean)
 *  	isChromium (Boolean)
 *  	isInWebAppiOS (Boolean)
 *  	isInWebAppChrome (Boolean)
 *  	isMobileSafari (Boolean)
 *  	isStandalone (Boolean)
 *  	isiPad (Boolean)
 *  	isiPhone (Boolean)
 *  	isCompatible (Boolean)
 * 	}
 * ==============================================================================================================================================================================================*/
export const checkPlatform = () => {
	// Información y capacidad del navegador
	var _ua = window.navigator.userAgent.toLowerCase();
	// Tipo de plataforma
	var platform = {};

	// Cada propiedad del objeto indica veredadero/falso el tipo de plataforma
	platform.isIosDevice = ( /iphone|ipod|ipad/i ).test( _ua );
	platform.isSamsung = /Samsung/i.test( _ua );
	platform.isEdge = /edg/i.test( _ua );
	// Opera y FireFox (o en Android)
	platform.isFireFox = (/Firefox/i.test( _ua ) || /android/i.test( _ua ));
	platform.isOpera = (/opr/i.test( _ua ) || /android/i.test( _ua ));

	platform.isChromium = ("onbeforeinstallprompt" in window);
	platform.isInWebAppiOS = (window.navigator.standalone === true);
	platform.isInWebAppChrome = matchMedia('(display-mode: standalone)').matches;
	platform.isMobileSafari = platform.isIosDevice && _ua.indexOf( 'Safari' ) > -1 && _ua.indexOf( 'CriOS' ) < 0;
	platform.isStandalone = platform.isInWebAppiOS || platform.isInWebAppChrome;
	platform.isiPad = (platform.isMobileSafari && _ua.indexOf( 'iPad' ) > -1);
	platform.isiPhone = (platform.isMobileSafari && _ua.indexOf( 'iPad' ) === -1);
	platform.isCompatible = (platform.isChromium || platform.isMobileSafari || platform.isSamsung || platform.isFireFox || platform.isOpera || platform.isEdge);

	// console.log( "platform.isiPhone: " + platform.isiPhone );
	// console.log( "platform.isMobileSafari: " + platform.isMobileSafari );
	// console.log( "platform.isInWebAppiOS: " + platform.isInWebAppiOS );
	// console.log( "platform.isCompatible: " + platform.isCompatible );

	return platform;
};

/** =============================================================================================================================================================================================
 *	getPlatform
 * 		Devuelve el nombre de la plataforma donde está corriendo la aplicación
 * ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
 * 	@param native As Boolean
 * ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
 * 	@returns String
 * ==============================================================================================================================================================================================*/
export const getPlatform = (native) => {

	var platform = checkPlatform();

	if ( platform.isChromium && ( native === undefined && !native ) ) {
		return "native";
	} else if ( platform.isFireFox ) {
		return "firefox";
	} else if ( platform.isiPad ) {
		return "ipad";
	} else if ( platform.isiPhone ) {
		return "iphone";
	} else if ( platform.isOpera ) {
		return "opera";
	} else if ( platform.isSamsung ) {
		return "samsung";
	} else if ( platform.isEdge ) {
		return "edge";
	} else if ( platform.isChromium ) {
		return "chromium";
	} else {
		return "";
	}

};

/** =============================================================================================================================================================================================
 *	Tools As Object
 * 		Objeto con todas las funciones declaradas anteriormente
 * ==============================================================================================================================================================================================*/
export const Tools = {
	hasStorage: hasStorage,
	slug: slug,
	hasEventListener: hasEventListener,
	checkPlatform: checkPlatform,
	getPlatform: getPlatform
};
