// Librerías
import React from 'react';

// Estilos
import '../../styles/parts/components/loading.scss'

class Loading extends React.Component {
	
	
	render(){
		
		return(
		
			<div className="loading text-center">
			
				<div className="spinner-border text-light" role="status">
				  <span className="sr-only">Cargando...</span>
				</div>
				
			</div>
		
		);
		
	}
	
}

export default Loading;