// Librerías
import React from 'react';
import { NavLink } from 'react-router-dom';
import { FirebaseContext } from '../../firebase';

// Estilos
import '../../styles/parts/components/topmenu.scss'

class TopMenu extends React.Component {
	
	// Cogemos referencia a Firebase del Context
	static contextType = FirebaseContext;

	constructor(props){
		super(props);
		this.onClickPrueba = this.onClickPrueba.bind(this);
		this.onClickLogout = this.onClickLogout.bind(this);		
	}
	

	onClickPrueba(){

		// Referencia a Firebase
		let api = this.context;

/*
		// Solicitamos un usuario directamente
		api.db.collection("usuarios").doc("dYh6Ubf5ahOjYkYnJgsLh8UWAug1").get().then(function(doc) {
			if (doc.exists) {
				alert("Document data: " + doc.data());
			} else {
				// doc.data() will be undefined in this case
				alert("No such document!");
			}
		}).catch(function(error) {
			alert("Error getting document: " + error);
		});
*/
		
		//firebase.firestore().collection("usuarios").doc("dYh6Ubf5ahOjYkYnJgsLh8UWAug1").get().then(function(respuesta){alert(respuesta);});

// Points to the root reference
var storageRef = api.file.ref();

// Points to 'images'
var imagesRef = storageRef.child('images');

// Points to 'images/space.jpg'
// Note that you can use variables to create child values
var fileName = 'space.jpg';
var spaceRef = imagesRef.child(fileName);

// File path is 'images/space.jpg'
var path = spaceRef.fullPath
alert("path: "+ path);
// File name is 'space.jpg'
var name = spaceRef.name
alert("name: "+ name);

// Points to 'images'
//var imagesRef = spaceRef.parent;

storageRef.child('images/space.jpg').getDownloadURL().then(function(url) {
	alert(JSON.stringify(url));
}).catch(function(error) {
	if(error.code==="404") alert("No encontrada");
});
		
	}
	
		
	
	onClickLogout(){

		// Mediante la referencia a Firebase, nos desconectamos
		this.context.Models.Auth.doLogout();
		
	}
	
	
	render(){
		
		const paths = this.context.Paths;

		return(
		
			<nav className="MenuSuperior navbar navbar-expand-lg navbar-light bg-light fixed-top">
			
				<NavLink exact className="navbar-brand" to="/">
					<img src={paths.assets.images + "ch42-logo.png"} height="30" className="d-inline-block align-top" alt="Logo" />
					&nbsp; Channel Shortener
				</NavLink>
				
				<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#MenuSuperior" aria-controls="MenuSuperior" aria-expanded="false" aria-label="Abrir/Cerrar Navegación">
					<span className="navbar-toggler-icon"></span>
				</button>

				<div className="collapse navbar-collapse" id="MenuSuperior">
					<ul className="navbar-nav mr-auto">
						<li className="nav-item">
							<NavLink exact className="nav-link" to="/usuarios">Usuarios</NavLink>
						</li>
					</ul>
					<div className="text-right">
						<button className="btn btn-primary" onClick={this.onClickPrueba}>Prueba</button> &nbsp;
						<button className="btn btn-primary" onClick={this.onClickLogout}>Logout</button>
					</div>
				</div>
			</nav>

		);
		
	}
	
}

export default TopMenu;
