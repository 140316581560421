import React from 'react';
import { FirebaseContext } from '../../firebase';

// Helpers
import * as _ from 'underscore'

// Componentes relacionados
import UserLinksTable from './userLinksTable';

class userForm extends React.Component {

	// Cogemos referencia a Firebase del Context
	static contextType = FirebaseContext;

	// Referencias
	userFormRef = {
		uid: React.createRef(),
		username: React.createRef(),
		firstname: React.createRef(),
		surnames: React.createRef(),
		activated: React.createRef(),
		image: React.createRef()
	}

	// ================================================================================================================================================================================================
	//	React
	// ================================================================================================================================================================================================

	constructor(props){ 
		super(props);

		this.state = {
			currUser: props.currUser,
			userLinks: {},
			percent: 0
		}
	}

	componentDidMount(){
		
		// Montamos el componente con los enlaces del Usuario
		this.context.Models.User.getChild(this.state.currUser.uid, 'links', function(related){
			this.setState({ userLinks: related });
		}.bind(this));
		
	}
	
	// ================================================================================================================================================================================================
	//	BEGIN :: Eventos
	// ================================================================================================================================================================================================
	//	onChangeUserImage As Void
	//		Evento click sobre la selección de imagen del formulario modal
	// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
	//	e As Event
	// ================================================================================================================================================================================================
	onChangeUserImage(evento){ evento.preventDefault();
	
		// Referencia a Firebase
		var fb = this.context;

		// Referencia a Storage
		var storage = fb.file.ref();
		
		// Archivo que queremos subir
		var currImage = this.userFormRef.image.current.files[0];
		
		// Los datos del Usuario
		var userData = this.props.currUser;

		// Subimos el archivo usando como nombre el uid de la noticia para sobreescribir la antigua. Indicamos que los metadatos son 'image/jpeg'
		var task = storage.child('users/' + userData.uid +  '.jpg').put(currImage, { contentType: 'image/jpeg' });

		// Establecemos el evento 'state_changed' para saber cómo va y cuándo termina
		task.on('state_changed', (info) => {
			// Cuando cambie el porcentaje de carga
			let percent = (info.bytesTransferred / info.totalBytes) * 100;
			this.setState({	percent: percent});
		}, (error) => {
			alert("error: " + JSON.stringify(error));
		}, () => {
			task.snapshot.ref.getDownloadURL().then(function(downloadURL) {
				userData.image = downloadURL;
				this.setState({ currUser: userData });
				
				// Tenemos que actualizar el valor de la imagen en la base de datos ya, porque ya hemos sobreescrito la imagen antigua
				fb.Models.User.setUser(userData.uid, { image: downloadURL });
				
				
			}.bind(this));
		});

	}
	
	// ================================================================================================================================================================================================
	//	onClickSave As Void
	//		Evento click sobre el botón <Guardar> del formulario modal
	// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
	//	e As Event
	// ================================================================================================================================================================================================
	onClickSave(e){ 
		e.preventDefault();

		// Referencia a Firebase
		let fb = this.context;

		// Los datos del Usuario
		var userData = this.props.currUser;

		// El UID del usuario estará en las props, pero si es nuevo, tenemos que coger el que hayan escrito en el textBox
		let uid = (_.isUndefined(userData.uid))? this.userFormRef.uid.current.value : userData.uid;
		
		// Guardamos el usuario
		fb.Models.User.setUser(uid,{
			uid: uid,
			username: this.userFormRef.username.current.value,
			firstname: this.userFormRef.firstname.current.value,
			surnames: this.userFormRef.surnames.current.value,
			activated: this.userFormRef.activated.current.checked,
		}, function(users){
			this.props.userFormClose(true);
		}.bind(this));

	}
	
	// ================================================================================================================================================================================================
	//	onClickCancel As Void
	//		Evento click sobre el botón <Cancelar> del formulario modal
	// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
	//	e As Event
	// ================================================================================================================================================================================================
	onClickCancel(e){ 
		e.preventDefault();

		this.props.close(false);
	}
	
	// ================================================================================================================================================================================================
	//	onClickDelete As Void
	//		Evento click sobre el botón <Borrar> del listado de la función <render>
	// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
	//	e As Event
	// ================================================================================================================================================================================================
	onClickDelete(e){ 
		e.preventDefault();
	
		let uid = e.currentTarget.getAttribute("uid");
		alert(uid);
	}
	// ================================================================================================================================================================================================
	//	END :: Eventos
	// ================================================================================================================================================================================================


	// Renderizado del componente, la salida HTML
	render(){

		// Los datos de Usuario
		const userData = this.props.currUser;
		const paths = this.context.Paths;

		// Para saber si es un usuario nuevo
		const bNewFlag = _.isUndefined(userData.uid);
			
		// Imagen que se mostrará, si no hay imagen se pondrá la imagen por defecto
		const userImage = (paths.assets.images + "nouser.jpg");

		return (
			<div id="usersForm" className="modal fade show" tabIndex="-1" style={{display:"block",background:"rgba(0,0,0,0.5)",overflow:"auto"}}>
				<div className="modal-dialog modal-lg" role="document">
					<div className="modal-content">
				
						<form>
							<div className="modal-header">
								<h5 className="modal-title" id="formUsuario">{bNewFlag?"Nuevo":"Editar"} Usuario</h5>
								<button type="button" className="close" onClick={this.onClickCancel.bind(this)}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
							
								<div className="row">
								
									<div className="col-12 col-sm-6">
										<div className="form-group">
											<div className="user-image" style={{backgroundImage: "url(" + userImage + ")"}} alt="Foto Perfil"></div>
										</div>
									</div>
									<div className="col-12 col-sm-6">
									
										{!bNewFlag && <div className="form-group">
											<label htmlFor="fileImage">Subir Nueva Imagen</label>
											<input type="file" className="form-control-file" id="fileImage" ref={this.userFormRef.image} onChange={this.onChangeUserImage.bind(this)} />
											<br />
											<div className="progress">
												<div className="progress-bar" role="progressbar" style={{width: this.state.percent+"%"}}>{this.state.percent} %</div>
											</div>
										</div>}
										
										{bNewFlag && <div className="alert alert-warning" role="alert">Guarde el usuario para poder editar la imagen</div>}
									
										<div className="form-group">

											<label htmlFor="txtUid">UID:</label>
											<input type="text" className="form-control" id="txtUid" ref={this.userFormRef.uid} value={userData.uid} readOnly={bNewFlag?false:true} />
										</div>
										<div className="form-group">
											<label htmlFor="txtUsername">ID de Usuario</label>
											<input type="text" className="form-control" id="txtUsername" ref={this.userFormRef.username} value={userData.username} readOnly={bNewFlag?false:true} />
										</div>
										<div className="form-group">
											<label htmlFor="txtUsername">Dirección de Correo</label>
											<input type="text" className="form-control" id="txtUsername" ref={this.userFormRef.usermail} value={userData.usermail} readOnly={bNewFlag?false:true} />
										</div>
										
									</div>
								</div>
								
								<div className="row">
								
									<div className="col-12 col-sm-4">
										<div className="form-group">
											<label htmlFor="txtFirstname">Nombre</label>
											<input type="text" className="form-control" id="txtFirstname" ref={this.userFormRef.firstname} defaultValue={userData.firstname} />
										</div>
									</div>
									<div className="col-12 col-sm-8">
										<div className="form-group">
											<label htmlFor="txtSurnames">Apellidos</label>
											<input type="text" className="form-control" id="txtSurnames" ref={this.userFormRef.surnames} defaultValue={userData.surnames} />
										</div>
									</div>


									<div className="col-12 col-sm-8">
										<div className="form-group">
											<label htmlFor="chkActivated">¿Activo?</label>
											<div className="form-check">
												<input type="checkbox" className="form-check-input" id="chkActivated" ref={this.userFormRef.activated} defaultChecked={userData.activated} />
												<label className="form-check-label" htmlFor="chkActivated">Activo</label>
											</div>
										</div>
									</div>
								</div>
								<hr />
								<UserLinksTable userLinks={this.state.userLinks} />
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-secondary" onClick={this.onClickCancel.bind(this)}>Cancelar</button>
								<button type="button" className="btn btn-primary" onClick={this.onClickSave.bind(this)}>Guardar</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		);		
	}
}

export default userForm;