import React from 'react';
import { FirebaseContext } from '../../firebase';
import * as _ from 'underscore'

// Componentes
import UsersListTable from './usersTable';
import UserForm from './userForm';

// Estilos
import '../../styles/parts/components/users.scss'

class Users extends React.Component {

	// Cogemos referencia a Firebase del Context
	static contextType = FirebaseContext;

	// ================================================================================================================================================================================================
	//	React
	// ================================================================================================================================================================================================

	constructor(props){ super(props);
		this.state = {
			usersList: {},
			htmlForm: { data: null, isOpen: false }
		}
	}

	componentDidMount(){
		this.usersTableUpdate();
	}

	render(){

		return(
			<div id="Users">
				<UsersListTable userslist={this.state.usersList} edit={this.onClickEdit.bind(this)} />
				{this.state.htmlForm.isOpen && <UserForm currUser={this.state.htmlForm.data} close={this.userFormClose.bind(this)} />}
			</div>
		);
	}		

	// ================================================================================================================================================================================================
	//	Eventos
	// ================================================================================================================================================================================================

	onClickEdit(e){
		e.preventDefault();
		
		// Cogemos el valor del atributo UID del elemento (este atributo podría estar vacío si se trata del botón "Nuevo")
		let uid = e.currentTarget.getAttribute("uid");
		
		// Si el UID no está vacío, cogemos referencia al usuario que corresponda del Array de usuarios de la tabla
		// Abrimos el formulario con ese usuario (si no hay usuario saldrá vacío para crear uno nuevo)
		if(!_.isEmpty(uid)) this.userFormOpen(this.state.usersList[uid]);
	}

	// ================================================================================================================================================================================================
	//	Tabla Usuarios
	// ================================================================================================================================================================================================
	
	usersTableUpdate(){
		
 		// Referencia a Firebase
		let api = this.context;

		// Solicitamos la lista de usuarios y la metemos en el State para que se actualice la tabla
		api.Models.User.getUsers(function(els){
			this.setState({ usersList: els });
		}.bind(this));
	}

	// ================================================================================================================================================================================================
	//	Form Usuarios
	// ================================================================================================================================================================================================

	userFormOpen(currUser){
		// Abrimos el formulario con el usuario seleccionado (si está vacío, saldrá en blanco para crear uno nuevo)
		this.setState({ htmlForm: { data: currUser, isOpen: true } });
	}
	
	userFormClose(isDirty){
		// Cerramos el formulario y refrescamos la tabla si hemos aceptado los cambios
		this.setState({ htmlForm: { data: null, isOpen: false } });
		if(isDirty) this.usersTableUpdate();
	}
}

export default Users;