import React from 'react';
import Chart from 'chart.js';
//import '../../estilos/Grafico.scss'

//import Api from '../api';
import { FirebaseContext } from '../../firebase';

//var Chart = require("chart.js");
 
class HomeGraphic extends React.Component {
	
	static contextType = FirebaseContext;

	componentDidMount(){
		
		const grafico = this.grafico;

		new Chart(grafico, {
		  type: "bar",
		  data: {
			labels: ["Rojo", "Azul", "Amarillo"],
			datasets: [{
				label: "Núm",
				data: [12, 19, 3],
				backgroundColor: ["#bd2130", "#0062cc", "#d39e00"]
			}]
		  } 
		});
	}
	
	render(){
		
		
		
		return(
			<canvas style={{ width: 800, height: 600 }} ref={grafico => (this.grafico = grafico)} />
		);
		
	}
	
}

export default HomeGraphic;