// Librerías
import React from 'react';
import { FirebaseContext } from '../firebase';
import * as _ from 'underscore';
import { Tools } from './Tools';

class AddToHomescreen extends React.Component {

    // Cogemos referencia a Firebase del Context
    static contextType = FirebaseContext;

    // Inicializamos el estado
    constructor(props) { 
        super(props);

        // Inicializamos el estado
        this.state = { 
            bannerAddToHomescreenVisible: false,
            buttonAddToHomescreen: React.createRef()
        };
    }

    componentDidMount(){
        // Instalación de la aplicación PWA
        window.addEventListener('appinstalled', this.onAppInstalled.bind(this, this));
        window.addEventListener('beforeinstallprompt', this.onBeforeInstallPrompt);
        // Botón de instalación de la App PWA
        if(!_.isUndefined(this.state.buttonAddToHomescreen.current) && !_.isNull(this.state.buttonAddToHomescreen.current))
            this.state.buttonAddToHomescreen.current.addEventListener('click', this.onAddToHomescreenClick.bind(this, this), false);
    }

    onAppInstalled(reactRef, e){
        console.log('a2hs installed');
    }

    onBeforeInstallPrompt(reactRef, e){

        reactRef.setState({bannerAddToHomescreenVisible: true});

        window.promptEvent = e;
    
        // Comprueba si la aplicación ya está instalada, en ese caso, no hace nada
        // Navegador de iOS
        if(window.navigator.standalone){
            return false;
        // El resto de navegadores
        }else if(window.matchMedia && matchMedia('(display-mode: standalone)').matches){
            return false;
        }

    }

    onAddToHomescreenClick(reactRef, e){
        if(!_.isUndefined(e)) e.preventDefault();

        if(_.isUndefined(window.promptEvent) || _.isNull(window.promptEvent)) return false;

        reactRef.onCloseBannerHomescreenClick(reactRef);

        console.log("Click en Instalar");
        // Show the prompt
        window.promptEvent.prompt();
        // Wait for the user to respond to the prompt
        window.promptEvent.userChoice.then((choiceResult) => {
            if(choiceResult.outcome === 'accepted'){
                console.log('El usuario aceptó el aviso de A2HS');
            }else{
                console.log('El usuario rechazó la solicitud de A2HS');
            }
            window.promptEvent = null;
        });
    }

    onCloseBannerHomescreenClick(reactRef, e){
        if(!_.isUndefined(e)) e.preventDefault();
        // TODO: guardar de alguna manera la elección de no instalar la APP en cookies, storage del navegador...
        // localStorage.setItem("NotInstall", true);
        reactRef.setState({ bannerAddToHomescreenVisible: false });
    }

    render() {
        const bannerVisible = this.state.bannerAddToHomescreenVisible;
        const shouldRender = ( 
            bannerVisible && 
            (Tools.checkPlatform().isIosDevice || Tools.checkPlatform().isStandalone || Tools.checkPlatform().isCompatible)
        );

        return (shouldRender ?
			<div id="prompt" className="d-flex">
                <div className="d-flex flex-grow-1 pl-4">
                    <div className="align-self-start"><img src={ this.context.paths.assets.iconsapp + "icon-72x72.png"} alt={this.context.App.name} /></div>
                    <div className="align-self-start py-2 px-4">
                        <span>Añadir a tu pantalla de Inicio</span><br />
                        <small>Esta aplicación se puede instalar en tu pantalla de Inicio</small>
                    </div>						
                </div>
                <div className="d-flex justify-content-end pr-4">
                    <div className="d-flex align-items-center">
                        <button id="buttonCancel" onClick={this.onCloseBannerHomescreenClick.bind(this, this)} type="button" className="font-weight-bold text-muted btn-sm btn btn-link">Ahora no</button>
                    </div>
                    <div className="d-flex align-items-center">
                        <button id="buttonAdd" ref={this.state.buttonAddToHomescreen} type="button" className="font-weight-bold text-primary btn-sm btn btn-link">INSTALAR</button>
                    </div>
                </div>
            </div>
        : null);
    }
}

export default AddToHomescreen;
